var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.individuationVisible,"width":"700px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.isModify ? '编辑' : '添加')+" ")]),_c('a-form',{attrs:{"form":_vm.form}},[(_vm.type == 'system')?_c('a-form-item',{attrs:{"label":"应用ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'clientId',
          {
            rules: [{ required: true, message: '请输入应用ID' }],
            initialValue: _vm.isModify ? _vm.individuationRecord.clientId : '',
          } ]),expression:"[\n          'clientId',\n          {\n            rules: [{ required: true, message: '请输入应用ID' }],\n            initialValue: isModify ? individuationRecord.clientId : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入应用ID"}})],1):_c('a-form-item',{attrs:{"label":"租户ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tenantId',
          {
            rules: [{ required: true, message: '请输入租户ID' }],
            initialValue: _vm.isModify ? _vm.individuationRecord.tenantId : '',
          } ]),expression:"[\n          'tenantId',\n          {\n            rules: [{ required: true, message: '请输入租户ID' }],\n            initialValue: isModify ? individuationRecord.tenantId : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入租户ID"}})],1),(_vm.type == 'system')?_c('a-form-item',{attrs:{"label":"应用名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'clientName',
          {
            rules: [{ required: true, message: '请输入应用名称' }],
            initialValue: _vm.isModify ? _vm.individuationRecord.clientName : '',
          } ]),expression:"[\n          'clientName',\n          {\n            rules: [{ required: true, message: '请输入应用名称' }],\n            initialValue: isModify ? individuationRecord.clientName : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入应用名称"}})],1):_c('a-form-item',{attrs:{"label":"租户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tenantName',
          {
            rules: [{ required: true, message: '请输入租户名称' }],
            initialValue: _vm.isModify ? _vm.individuationRecord.tenantName : '',
          } ]),expression:"[\n          'tenantName',\n          {\n            rules: [{ required: true, message: '请输入租户名称' }],\n            initialValue: isModify ? individuationRecord.tenantName : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入应用名称"}})],1),_c('a-form-item',{attrs:{"label":"短信签名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tencentSignatureName',
          {
            initialValue: _vm.isModify
              ? _vm.individuationRecord.sms.tencentSignatureName
              : '',
          } ]),expression:"[\n          'tencentSignatureName',\n          {\n            initialValue: isModify\n              ? individuationRecord.sms.tencentSignatureName\n              : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入短信签名"}})],1),_c('div',{staticClass:"formItemTitle"},[_vm._v("邮箱服务配置")]),_c('a-form-item',{attrs:{"label":"是否使用SSL加密","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'enableSSL',
          {
            initialValue: _vm.isModify ? _vm.individuationRecord.smtp.enableSSL : '',
          } ]),expression:"[\n          'enableSSL',\n          {\n            initialValue: isModify ? individuationRecord.smtp.enableSSL : '',\n          },\n        ]"}],attrs:{"name":"radioGroup"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"smtp服务器域名","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'host',
          {
            initialValue: _vm.isModify ? _vm.individuationRecord.smtp.host : '',
          } ]),expression:"[\n          'host',\n          {\n            initialValue: isModify ? individuationRecord.smtp.host : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入smtp服务器域名"}})],1),_c('a-form-item',{attrs:{"label":"smtp服务器端口","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'port',
          {
            initialValue: _vm.isModify ? _vm.individuationRecord.smtp.port : '',
          } ]),expression:"[\n          'port',\n          {\n            initialValue: isModify ? individuationRecord.smtp.port : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入smtp服务器端口"}})],1),_c('a-form-item',{attrs:{"label":"发送人的邮箱账号","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            initialValue: _vm.isModify ? _vm.individuationRecord.smtp.username : '',
          } ]),expression:"[\n          'username',\n          {\n            initialValue: isModify ? individuationRecord.smtp.username : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入发送人的邮箱账号"}})],1),_c('a-form-item',{attrs:{"label":"发送人的邮箱密码","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            initialValue: _vm.isModify ? _vm.individuationRecord.smtp.password : '',
          } ]),expression:"[\n          'password',\n          {\n            initialValue: isModify ? individuationRecord.smtp.password : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入发送人的邮箱密码"}})],1),_c('a-form-item',{attrs:{"label":"发送人名称","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'personalAlias',
          {
            initialValue: _vm.isModify
              ? _vm.individuationRecord.smtp.personalAlias
              : '',
          } ]),expression:"[\n          'personalAlias',\n          {\n            initialValue: isModify\n              ? individuationRecord.smtp.personalAlias\n              : '',\n          },\n        ]"}],attrs:{"placeholder":"请输入发送人名称"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }