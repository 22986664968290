<template>
  <a-modal
    :visible="templateVisible"
    @ok="handleOk"
    @cancel="handleCancel"
    width="800px"
  >
    <div slot="title">{{ isModify ? '编辑模板' : '添加模板' }}</div>

    <a-form :form="form">
      <a-form-item label="渠道类型" v-if="!isModify">
        <a-select
          v-decorator="[
            'channel',
            { rules: [{ required: true, message: '请选择渠道类型' }] },
          ]"
          @change="selectedChange"
        >
          <a-select-option
            v-for="item in channelList"
            :key="item.value"
            :disabled="type == 'business' && ['JIGUANG'].includes(item.value)"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <!-- <a-dropdown
        class="variable"
        v-if="templateData.channel == 'JIGUANG' || channel == 'JIGUANG'"
      >
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          插入变量 <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item v-for="item in variableData" :key="item.id">
            <span @click="_insertParam(item.name)">{{ item.description }}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-form-item
        label="通知标题模板"
        v-if="templateData.channel == 'JIGUANG' || channel == 'JIGUANG'"
      >
        <a-textarea
          ref="templateTitle"
          :rows="1"
          v-decorator="[
            'title',
            {
              rules: [{ required: true, message: '请输入模板标题' }],
              initialValue: isModify ? templateData.title : '',
            },
          ]"
        ></a-textarea>
      </a-form-item> -->

      <a-dropdown class="variable">
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          插入变量 <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item v-for="item in variableData" :key="item.id">
            <!-- <span @click="addText(item)">{{ item.description }}</span> -->
            <span @click="insertParam(item.name)">{{ item.description }}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-form-item label="通知内容模板">
        <a-textarea
          ref="templateContent"
          :rows="
            ['EMAIL', 'IM'].includes(templateData.channel) ||
            ['EMAIL', 'IM'].includes(channel)
              ? 10
              : 3
          "
          v-decorator="[
            'content',
            {
              rules: [{ required: true, message: '请输入模板内容' }],
              initialValue: isModify ? templateData.content : '',
            },
          ]"
        ></a-textarea>
      </a-form-item>
      <a-form-item
        label="第三方模板ID"
        v-if="
          ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(
            templateData.channel
          ) ||
          ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(channel)
        "
      >
        <a-input
          placeholder="请输入第三方模板ID"
          v-decorator="[
            'thirdPartTemplateId',
            { initialValue: isModify ? templateData.thirdPartTemplateId : '' },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="第三方发送签名"
        v-if="
          ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(
            templateData.channel
          ) ||
          ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(channel)
        "
      >
        <a-input
          placeholder="请输入第三方发送签名"
          v-decorator="[
            'thirdPartSignature',
            { initialValue: isModify ? templateData.thirdPartSignature : '' },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="第三方模板内容"
        v-if="
          ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(
            templateData.channel
          ) ||
          ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(channel)
        "
      >
        <a-textarea
          :rows="3"
          placeholder="请输入第三方木模板内容"
          v-decorator="[
            'thirdPartContent',
            { initialValue: isModify ? templateData.thirdPartContent : '' },
          ]"
        ></a-textarea>
      </a-form-item>
      <!-- 极光 -->
      <!-- <a-form-item
        label="包名"
        v-if="templateData.channel == 'JIGUANG' || channel == 'JIGUANG'"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'jiguangPackageName',
            {
              rules: [{ required: true, message: '请填写包名' }],
              initialValue: templateData.jiguangPackageName || '',
            },
          ]"
        ></a-input>
      </a-form-item> -->
      <a-form-item
        label="appKey"
        v-if="templateData.channel == 'JIGUANG' || channel == 'JIGUANG'"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'jiguangAppKey',
            {
              rules: [{ required: true, message: '请填写appKey' }],
              initialValue: templateData.jiguangAppKey || '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="appsecret"
        v-if="templateData.channel == 'JIGUANG' || channel == 'JIGUANG'"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'jiguangMasterSecret',
            {
              rules: [{ required: true, message: '请填写appsecret' }],
              initialValue: templateData.jiguangMasterSecret || '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <!-- 选择极光推送平台---------- -->
      <a-form-item
        label="选择平台"
        v-if="templateData.channel == 'JIGUANG' || channel == 'JIGUANG'"
      >
        <a-checkbox-group
          @change="platformChange"
          v-decorator="[
            'plat',
            {
              rules: [{ required: true, message: '请选择平台' }],
              initialValue: jiguangConfig.plat,
            },
          ]"
        >
          <a-checkbox value="ANDROID">Android</a-checkbox>
          <a-checkbox value="IOS">iOS</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <!-- 安卓 -->
      <a-form-item
        label="Android"
        v-if="
          (templateData.channel == 'JIGUANG' || channel == 'JIGUANG') &&
          (jiguangConfig.plat.includes('ANDROID') ||
            platform.includes('ANDROID'))
        "
      >
        <a-form-item label="包名">
          <a-input
            v-decorator="[
              'androidPackage',
              {
                rules: [{ required: true, message: '请填写安卓包名' }],
                initialValue: jiguangConfig.androidConfig.jiguangPackageName,
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="点击通知打开">
          <a-checkbox-group
            @change="androidOpenChange"
            v-decorator="[
              'androidOpen',
              {
                initialValue: jiguangConfig.androidConfig.openMethod
                  ? [jiguangConfig.androidConfig.openMethod]
                  : ['OPEN_APPLICATION'],
              },
            ]"
          >
            <a-checkbox value="OPEN_APPLICATION">打开应用</a-checkbox>
            <a-checkbox value="INTENT">Intent(推荐)</a-checkbox>
            <a-checkbox value="DEEPLINK">DeepLink</a-checkbox>
            <a-checkbox value="URL">URL</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label=""
          v-if="
            ['INTENT', 'DEEPLINK', 'URL'].includes(
              jiguangConfig.androidConfig.openMethod
            )
          "
        >
          <a-input
            :placeholder="androidPlaceholder"
            v-decorator="[
              'androidExtras',
              {
                rules: [{ required: true, message: '请输入' }],
                initialValue:
                  jiguangConfig.androidConfig.extras &&
                  jiguangConfig.androidConfig.extras.intent
                    ? JSON.parse(jiguangConfig.androidConfig.extras.intent).url
                    : '',
              },
            ]"
          ></a-input>
        </a-form-item>
      </a-form-item>
      <!-- ios -->
      <a-form-item
        label="iOS"
        v-if="
          (templateData.channel == 'JIGUANG' || channel == 'JIGUANG') &&
          (jiguangConfig.plat.includes('IOS') || platform.includes('IOS'))
        "
      >
        <a-form-item label="包名">
          <a-input
            v-decorator="[
              'iosPackage',
              {
                rules: [{ required: true, message: '请填写ios包名' }],
                initialValue: jiguangConfig.iosConfig.jiguangPackageName,
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="点击通知打开">
          <a-checkbox-group
            @change="iosOpenChange"
            v-decorator="[
              'iosOpen',
              {
                initialValue: jiguangConfig.iosConfig.openMethod
                  ? [jiguangConfig.iosConfig.openMethod]
                  : ['OPEN_APPLICATION'],
              },
            ]"
          >
            <a-checkbox value="OPEN_APPLICATION">打开应用</a-checkbox>
            <a-checkbox value="PAGE">指定页面</a-checkbox>
            <a-checkbox value="URL">URL</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label=""
          v-if="['URL'].includes(jiguangConfig.iosConfig.openMethod)"
        >
          <a-input
            placeholder="请输入URL"
            v-decorator="[
              'iosExtras',
              {
                rules: [{ required: true, message: '请输入URL' }],
                initialValue:
                  jiguangConfig.iosConfig.extras &&
                  jiguangConfig.iosConfig.extras.intent
                    ? JSON.parse(jiguangConfig.iosConfig.extras.intent).url
                    : '',
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item
          label=""
          v-if="jiguangConfig.iosConfig.openMethod == 'PAGE'"
          class="row-input"
        >
          <a-input
            placeholder="键"
            style="width: 120px"
            v-decorator="[
              '_key',
              {
                initialValue: jiguangConfig.iosConfig.extras
                  ? Object.keys(jiguangConfig.iosConfig.extras)[0]
                  : '',
              },
            ]"
          ></a-input
          >:
          <a-select
            style="width: 100px"
            @change="valueTypeChange"
            v-decorator="[
              'valueType',
              {
                initialValue: jiguangConfig.iosConfig.extras
                  ? typeof Object.values(jiguangConfig.iosConfig.extras)[0]
                  : 'string',
              },
            ]"
          >
            <a-select-option value="string">字符串</a-select-option>
            <a-select-option value="boolean">boolean</a-select-option>
            <a-select-option value="number">number</a-select-option>
          </a-select>

          <a-select
            v-if="valueType == 'boolean'"
            style="width: 400px"
            placeholder="值"
            v-decorator="[
              '_value',
              {
                initialValue: jiguangConfig.iosConfig.extras
                  ? Object.values(jiguangConfig.iosConfig.extras)[0]
                  : '',
              },
            ]"
          >
            <a-select-option :value="true">true</a-select-option>
            <a-select-option :value="false">false</a-select-option>
          </a-select>
          <a-input-number
            v-else-if="valueType == 'number'"
            style="width: 400px"
            placeholder="值"
            v-decorator="[
              '_value',
              {
                initialValue: jiguangConfig.iosConfig.extras
                  ? Object.values(jiguangConfig.iosConfig.extras)[0]
                  : 1,
              },
            ]"
          ></a-input-number>
          <a-input
            style="width: 400px"
            placeholder="值"
            v-else
            v-decorator="[
              '_value',
              {
                initialValue: jiguangConfig.iosConfig.extras
                  ? Object.values(jiguangConfig.iosConfig.extras)[0]
                  : '',
              },
            ]"
          ></a-input>
        </a-form-item>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EditTemplate',
  inject: ['reload'],
  props: {
    templateVisible: {
      type: Boolean,
      default: false,
    },
    templateData: {
      type: Object,
      default: () => {},
    },
    variableData: {
      type: Array,
      default: () => [],
    },
    scenarioId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'system',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      variableIds: [],
      channelList: [
        { name: '站内信', value: 'IM' },
        { name: '邮箱', value: 'EMAIL' },
        { name: '华为云', value: 'HUAWEI_SMS' },
        { name: '腾讯云', value: 'TENCENT_SMS' },
        { name: '微信公众号', value: 'WX_MP' },
        { name: '极光', value: 'JIGUANG' },
      ],
      channel: '',
      // platformList: [
      //   {
      //     name: 'Android',
      //     value: '0',
      //   },
      //   {
      //     name: 'iOS',
      //     value: '1',
      //   },
      // ],
      platform: [],
      androidOpen: '',
      iosOpen: '',
      valueType: '' /* ios指定页面键值时，值的类型 */,
      androidPlaceholder: '',
      jiguangConfig: {
        androidConfig: {
          extras: {},
          jiguangPackageName: '',
          openMethod: '',
        },
        iosConfig: {
          extras: {},
          jiguangPackageName: '',
          openMethod: '',
        },
        plat: [],
      },
    }
  },
  created() {
    console.log(
      this.templateData,
      '渠道模板数据',
      this.templateData.channel,
      this.scenarioId
    )
    if (this.isModify) {
      this.jiguangConfig = this.templateData.jiguangConfig.plat
        ? this.templateData.jiguangConfig
        : this.jiguangConfig
      if (this.jiguangConfig.iosConfig.openMethod) {
        this.valueType =
          typeof Object.values(this.jiguangConfig.iosConfig.extras)[0] ||
          'string'
      }
    }
  },
  computed: {
    isModify() {
      return Object.keys(this.templateData).length > 0
    },
  },
  methods: {
    /* 渠道类型切换 */
    selectedChange(value) {
      console.log(value)
      this.channel = value
    },
    platformChange(value) {
      // console.log('被输出值{  }的输出结果是：', value)
      this.platform = value
    },
    androidOpenChange(value) {
      // console.log('被输出值{  }的输出结果是：', value)
      // this.androidOpen = value

      if (value.length >= 1) {
        // this.androidOpen = value.pop()
        // value = [this.androidOpen]
        this.jiguangConfig.androidConfig.openMethod = value.pop()
        value = [this.jiguangConfig.androidConfig.openMethod]
      } else {
        // this.androidOpen = ''
        this.jiguangConfig.androidConfig.openMethod = ''
        value = []
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          androidOpen: [this.jiguangConfig.androidConfig.openMethod],
          androidExtras: '',
        })
      })
      /* 修改提示 */
      switch (this.jiguangConfig.androidConfig.openMethod) {
        case 'OPEN_APPLICATION':
          break
        case 'INTENT':
          this.androidPlaceholder =
            // 'intent:#Intent;action=action路径;component=包名/Activity组件路径;end'
            'intent:#intent;action=action路径;component=包名/Activity组件路径;变量列表(固定写${variables}以获取全部变量);end'
          break
        case 'DEEPLINK':
          this.androidPlaceholder = 'scheme://host:port/path?query=xxx'
          break
        case 'URL':
          this.androidPlaceholder = '请输入URL'
          break
      }

      console.log('被输出值{  }的输出结果是：', value)
    },
    iosOpenChange(value) {
      // console.log('被输出值{  }的输出结果是：', value)
      // this.androidOpen = value

      if (value.length >= 1) {
        // this.iosOpen = value.pop()
        // value = [this.iosOpen]
        this.jiguangConfig.iosConfig.openMethod = value.pop()
        value = this.jiguangConfig.iosConfig.openMethod
      } else {
        this.jiguangConfig.iosConfig.openMethod = ''
        value = []
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          iosOpen: [this.jiguangConfig.iosConfig.openMethod],
          iosExtras: '',
          _key: '',
          _value: '',
          valueType: 'string',
        })
      })

      console.log('被输出值{  }的输出结果是：', value)
    },
    valueTypeChange(value) {
      console.log('被输出值{ 值类型 }的输出结果是：', value)
      this.valueType = value
      if ((value = 'number')) {
        this.form.setFieldsValue({
          _value: Number(Object.values(this.jiguangConfig.iosConfig.extras)[0]),
        })
      } else if (valueType == 'string') {
        this.form.setFieldsValue({
          _value: Object.values(
            this.jiguangConfig.iosConfig.extras
          )[0].toString(),
        })
      }
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let variableArr = this.getVariable(values.content)
          if (variableArr.length == 0) {
          } else {
            this.getVariableIds(variableArr)
          }
          let data = {
            ...values,
            variableIds: this.variableIds,
          }
          if (this.isModify)
            this.$set(values, 'channel', this.templateData.channel)
          if (values.channel == 'JIGUANG') {
            /* 处理极光配置 */
            let jiguangConfig = {
              plat: values.plat,
            }

            if (values.plat.includes('ANDROID')) {
              let jsonStr = {}
              jsonStr = values.androidExtras
                ? { url: values.androidExtras }
                : ''
              if (values.androidOpen.includes('OPEN_APPLICATION'))
                jsonStr = {
                  url: 'intent:#Intent;action=android.intent.action.MAIN;end',
                }
              let androidConfig = {
                jiguangPackageName: values.androidPackage,
                openMethod: values.androidOpen[0] || 'EMPTY',
                extras: { intent: JSON.stringify(jsonStr) },
              }
              Object.assign(jiguangConfig, { androidConfig })
            }
            if (values.plat.includes('IOS')) {
              let jsonStr = {}
              jsonStr = values.iosExtras ? { url: values.iosExtras } : ''
              if (values.iosOpen.includes('OPEN_APPLICATION')) {
                jsonStr = {
                  url: 'intent:#Intent;action=ios.intent.action.MAIN;end',
                }
              } else if (values.iosOpen.includes('PAGE')) {
                // this.$set(jsonStr, values.key, values.value)
                // Object.assign(jsonStr, { [values._key]: values._value })
                jsonStr = { [values._key]: values._value }
                console.log(
                  '被输出值{ key,value }的输出结果是：',
                  values._key,
                  values._value,
                  jsonStr
                )
              }

              let iosConfig = {
                jiguangPackageName: values.iosPackage,
                openMethod: values.iosOpen[0] || 'EMPTY',
                // extras: {
                //   intent: values.iosOpen.includes('PAGE')
                //     ? jsonStr
                //     : JSON.stringify(jsonStr),
                // },
                extras: values.iosOpen.includes('PAGE')
                  ? { ...jsonStr }
                  : { intent: JSON.stringify(jsonStr) },
              }
              Object.assign(jiguangConfig, { iosConfig })
            }

            Object.assign(data, { jiguangConfig })
          }
          console.log('被输出值{ data }的输出结果是：', data)
          if (this.isModify) {
            /* 编辑模板 */
            this.updateTemplate(this.templateData.templateId, data).then(() => {
              // this.reload()
              this.$emit('refresh')
            })
          } else {
            /* 添加模板 */
            let _data = {
              ...data,
              status: 'ENABLED',
            }
            this.$set(_data, 'scenarioId', this.scenarioId)
            this.addTemplate(_data).then(() => {
              // this.reload()
              this.$emit('refresh')
            })
          }
        }
      })
    },
    /* 更新模板数据（状态） */
    updateTemplate(templateId, modifyData) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateTemplate(templateId, modifyData)
          .then((res) => {
            // console.log(res)
            if (res.code === 0) {
              this.$message.success('修改成功')
              resolve()
            } else if (res.code == 1000) {
              this.$message.error('变量表达式错误！')
              reject()
            }
          })
          .catch((err) => {
            this.$message.error('修改失败')
            reject(err)
          })
      })
    },
    /* 新增模板 */
    addTemplate(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .addTemplateChannel(data)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success('添加成功')
              resolve()
            }
          })
          .catch((err) => {
            this.$message.error('添加失败')
            reject(err)
          })
      })
    },
    handleCancel() {
      this.$emit('update:templateVisible', false)
    },
    /* 插入变量 */
    addText(item) {
      this.form.setFieldsValue({
        content: `${this.form.getFieldValue('content')} \$\{${item.name}\}`,
      })
    },
    /* 光标位置插入变量 */
    async insertParam(variable) {
      const str = '${' + variable + '}'
      const { selectionEnd, value } = this.$refs.templateContent.$el
      if (selectionEnd || selectionEnd === 0) {
        const front = value.slice(0, selectionEnd)
        const back = value.slice(selectionEnd)
        // this.templateContent = front + str + back
        this.form.setFieldsValue({
          content: front + str + back,
        })
        this.$nextTick(() => {
          this.$refs.templateContent.$el.focus()
          this.$refs.templateContent.$el.setSelectionRange(
            selectionEnd + str.length,
            selectionEnd + str.length
          )
        })
      } else {
        // this.templateContent = this.templateContent + '${' + variable + '}'
        this.form.setFieldsValue({
          content: `${this.form.getFieldValue('content')} \$\{${variable}\}`,
        })
      }
    },
    async _insertParam(variable) {
      const str = '${' + variable + '}'
      const { selectionEnd, value } = this.$refs.templateTitle.$el
      if (selectionEnd || selectionEnd === 0) {
        const front = value.slice(0, selectionEnd)
        const back = value.slice(selectionEnd)
        // this.templateContent = front + str + back
        this.form.setFieldsValue({
          title: front + str + back,
        })
        this.$nextTick(() => {
          this.$refs.templateTitle.$el.focus()
          this.$refs.templateTitle.$el.setSelectionRange(
            selectionEnd + str.length,
            selectionEnd + str.length
          )
        })
      } else {
        // this.templateContent = this.templateContent + '${' + variable + '}'
        this.form.setFieldsValue({
          title: `${this.form.getFieldValue('title')} \$\{${variable}\}`,
        })
      }
    },

    /* 提取符合${}的变量 */
    getVariable(str) {
      let reg = /\$\{(.+?)\}/
      let reg_g = /\$\{(.+?)\}/g
      let result = str.match(reg_g)
      if (result == null) {
        return []
      } else {
        let list = result.map((item) => {
          return (item = item.match(reg)[1])
        })
        return list
      }
    },

    /* 获取所有变量相应的变量Id */
    getVariableIds(variableNameArr) {
      this.variableIds = []
      variableNameArr.forEach((nameItem) => {
        let newIds = this.variableData.filter(
          (varItem) => varItem.name == nameItem
        )
        this.variableIds = [...this.variableIds, ...newIds]
      })
      // console.log(this.variableIds)
      this.variableIds = this.variableIds.map((item) => {
        return (item = item.variableId)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.variable {
  position: absolute;
  right: 24px;
  z-index: 99;
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

/deep/ .row-input {
  display: flex;
  .ant-form-item-control-wrapper {
    width: 100%;
  }
}
</style>
