<template>
  <a-modal
    :visible="individuationVisible"
    @ok="handleOk"
    @cancel="handleCancel"
    width="700px"
  >
    <div slot="title">
      {{ isModify ? '编辑' : '添加' }}
    </div>
    <a-form :form="form">
      <a-form-item label="应用ID" v-if="type == 'system'">
        <a-input
          placeholder="请输入应用ID"
          v-decorator="[
            'clientId',
            {
              rules: [{ required: true, message: '请输入应用ID' }],
              initialValue: isModify ? individuationRecord.clientId : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="租户ID" v-else>
        <a-input
          placeholder="请输入租户ID"
          v-decorator="[
            'tenantId',
            {
              rules: [{ required: true, message: '请输入租户ID' }],
              initialValue: isModify ? individuationRecord.tenantId : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="应用名称" v-if="type == 'system'">
        <a-input
          placeholder="请输入应用名称"
          v-decorator="[
            'clientName',
            {
              rules: [{ required: true, message: '请输入应用名称' }],
              initialValue: isModify ? individuationRecord.clientName : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="租户名称" v-else>
        <a-input
          placeholder="请输入应用名称"
          v-decorator="[
            'tenantName',
            {
              rules: [{ required: true, message: '请输入租户名称' }],
              initialValue: isModify ? individuationRecord.tenantName : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="短信签名">
        <a-input
          placeholder="请输入短信签名"
          v-decorator="[
            'tencentSignatureName',
            {
              initialValue: isModify
                ? individuationRecord.sms.tencentSignatureName
                : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <div class="formItemTitle">邮箱服务配置</div>
      <a-form-item
        label="是否使用SSL加密"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-radio-group
          name="radioGroup"
          v-decorator="[
            'enableSSL',
            {
              initialValue: isModify ? individuationRecord.smtp.enableSSL : '',
            },
          ]"
        >
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="smtp服务器域名"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请输入smtp服务器域名"
          v-decorator="[
            'host',
            {
              initialValue: isModify ? individuationRecord.smtp.host : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="smtp服务器端口"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请输入smtp服务器端口"
          v-decorator="[
            'port',
            {
              initialValue: isModify ? individuationRecord.smtp.port : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="发送人的邮箱账号"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请输入发送人的邮箱账号"
          v-decorator="[
            'username',
            {
              initialValue: isModify ? individuationRecord.smtp.username : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="发送人的邮箱密码"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请输入发送人的邮箱密码"
          v-decorator="[
            'password',
            {
              initialValue: isModify ? individuationRecord.smtp.password : '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        label="发送人名称"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请输入发送人名称"
          v-decorator="[
            'personalAlias',
            {
              initialValue: isModify
                ? individuationRecord.smtp.personalAlias
                : '',
            },
          ]"
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EditIndividuation',
  inject: ['reload'],
  props: {
    individuationVisible: {
      type: Boolean,
      default: false,
    },
    individuationRecord: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    scenarioId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      isModify: Object.keys(this.individuationRecord).length,
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values, '表单')
          /* 处理数据 */
          let { customizationId, scenarioIds } = this.individuationRecord
          let {
            tenantId,
            tenantName,
            clientName,
            clientId,
            tencentSignatureName,
            host,
            port,
            username,
            password,
            enableSSL,
            personalAlias,
          } = values

          let data = {
            customizationId,
            tenantId,
            clientId,
            clientName,
            tenantName,
            smsDTO: {
              tencentSignatureName,
            },
            smtpDTO: {
              host,
              port,
              username,
              password,
              enableSSL,
              personalAlias,
            },
            scenarioIds,
          }

          data = this.cleanEmpty(data)

          // console.log(data, '参数')
          /* 发送修改个性化配置请求 */
          if (this.isModify) {
            this.$api
              .updateCustomization(data)
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success('保存成功')
                  this.$emit('refresh')
                }
              })
              .catch((err) => {
                this.$message.error('保存失败')
                console.log(err, '保存失败')
              })
          } else {
            /* 发送添加个性化配置请求 */
            this.$set(data, 'scenarioId', [this.scenarioId])
            this.$api
              .addCustomization(data)
              .then((res) => {
                // console.log(res, '添加个性化配置')
                if (res.code === 0) {
                  this.$message.success('添加成功')
                  this.$emit('refresh')
                }
              })
              .catch((err) => {
                this.$message.error('添加失败')
                console.log(err, '添加失败')
              })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:individuationVisible', false)
    },
    cleanEmpty(object) {
      return Object.keys(object)
        .filter((key) => {
          return (
            object[key] !== ' ' &&
            object[key] !== null &&
            object[key] !== undefined
          )
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr]: object[curr] }
        }, {})
    },
  },
}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.formItemTitle {
  font-weight: bold;
}
</style>
