var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.templateVisible,"width":"800px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.isModify ? '编辑模板' : '添加模板'))]),_c('a-form',{attrs:{"form":_vm.form}},[(!_vm.isModify)?_c('a-form-item',{attrs:{"label":"渠道类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channel',
          { rules: [{ required: true, message: '请选择渠道类型' }] } ]),expression:"[\n          'channel',\n          { rules: [{ required: true, message: '请选择渠道类型' }] },\n        ]"}],on:{"change":_vm.selectedChange}},_vm._l((_vm.channelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"disabled":_vm.type == 'business' && ['JIGUANG'].includes(item.value)}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),_c('a-dropdown',{staticClass:"variable"},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function (e) { return e.preventDefault(); }}},[_vm._v(" 插入变量 "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.variableData),function(item){return _c('a-menu-item',{key:item.id},[_c('span',{on:{"click":function($event){return _vm.insertParam(item.name)}}},[_vm._v(_vm._s(item.description))])])}),1)],1),_c('a-form-item',{attrs:{"label":"通知内容模板"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          {
            rules: [{ required: true, message: '请输入模板内容' }],
            initialValue: _vm.isModify ? _vm.templateData.content : '',
          } ]),expression:"[\n          'content',\n          {\n            rules: [{ required: true, message: '请输入模板内容' }],\n            initialValue: isModify ? templateData.content : '',\n          },\n        ]"}],ref:"templateContent",attrs:{"rows":['EMAIL', 'IM'].includes(_vm.templateData.channel) ||
          ['EMAIL', 'IM'].includes(_vm.channel)
            ? 10
            : 3}})],1),(
        ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(
          _vm.templateData.channel
        ) ||
        ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(_vm.channel)
      )?_c('a-form-item',{attrs:{"label":"第三方模板ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'thirdPartTemplateId',
          { initialValue: _vm.isModify ? _vm.templateData.thirdPartTemplateId : '' } ]),expression:"[\n          'thirdPartTemplateId',\n          { initialValue: isModify ? templateData.thirdPartTemplateId : '' },\n        ]"}],attrs:{"placeholder":"请输入第三方模板ID"}})],1):_vm._e(),(
        ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(
          _vm.templateData.channel
        ) ||
        ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(_vm.channel)
      )?_c('a-form-item',{attrs:{"label":"第三方发送签名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'thirdPartSignature',
          { initialValue: _vm.isModify ? _vm.templateData.thirdPartSignature : '' } ]),expression:"[\n          'thirdPartSignature',\n          { initialValue: isModify ? templateData.thirdPartSignature : '' },\n        ]"}],attrs:{"placeholder":"请输入第三方发送签名"}})],1):_vm._e(),(
        ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(
          _vm.templateData.channel
        ) ||
        ['HUAWEI_SMS', 'TENCENT_SMS', 'WX_MP', 'DEFAULT'].includes(_vm.channel)
      )?_c('a-form-item',{attrs:{"label":"第三方模板内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'thirdPartContent',
          { initialValue: _vm.isModify ? _vm.templateData.thirdPartContent : '' } ]),expression:"[\n          'thirdPartContent',\n          { initialValue: isModify ? templateData.thirdPartContent : '' },\n        ]"}],attrs:{"rows":3,"placeholder":"请输入第三方木模板内容"}})],1):_vm._e(),(_vm.templateData.channel == 'JIGUANG' || _vm.channel == 'JIGUANG')?_c('a-form-item',{attrs:{"label":"appKey"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'jiguangAppKey',
          {
            rules: [{ required: true, message: '请填写appKey' }],
            initialValue: _vm.templateData.jiguangAppKey || '',
          } ]),expression:"[\n          'jiguangAppKey',\n          {\n            rules: [{ required: true, message: '请填写appKey' }],\n            initialValue: templateData.jiguangAppKey || '',\n          },\n        ]"}],attrs:{"placeholder":"请填写"}})],1):_vm._e(),(_vm.templateData.channel == 'JIGUANG' || _vm.channel == 'JIGUANG')?_c('a-form-item',{attrs:{"label":"appsecret"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'jiguangMasterSecret',
          {
            rules: [{ required: true, message: '请填写appsecret' }],
            initialValue: _vm.templateData.jiguangMasterSecret || '',
          } ]),expression:"[\n          'jiguangMasterSecret',\n          {\n            rules: [{ required: true, message: '请填写appsecret' }],\n            initialValue: templateData.jiguangMasterSecret || '',\n          },\n        ]"}],attrs:{"placeholder":"请填写"}})],1):_vm._e(),(_vm.templateData.channel == 'JIGUANG' || _vm.channel == 'JIGUANG')?_c('a-form-item',{attrs:{"label":"选择平台"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'plat',
          {
            rules: [{ required: true, message: '请选择平台' }],
            initialValue: _vm.jiguangConfig.plat,
          } ]),expression:"[\n          'plat',\n          {\n            rules: [{ required: true, message: '请选择平台' }],\n            initialValue: jiguangConfig.plat,\n          },\n        ]"}],on:{"change":_vm.platformChange}},[_c('a-checkbox',{attrs:{"value":"ANDROID"}},[_vm._v("Android")]),_c('a-checkbox',{attrs:{"value":"IOS"}},[_vm._v("iOS")])],1)],1):_vm._e(),(
        (_vm.templateData.channel == 'JIGUANG' || _vm.channel == 'JIGUANG') &&
        (_vm.jiguangConfig.plat.includes('ANDROID') ||
          _vm.platform.includes('ANDROID'))
      )?_c('a-form-item',{attrs:{"label":"Android"}},[_c('a-form-item',{attrs:{"label":"包名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'androidPackage',
            {
              rules: [{ required: true, message: '请填写安卓包名' }],
              initialValue: _vm.jiguangConfig.androidConfig.jiguangPackageName,
            } ]),expression:"[\n            'androidPackage',\n            {\n              rules: [{ required: true, message: '请填写安卓包名' }],\n              initialValue: jiguangConfig.androidConfig.jiguangPackageName,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"点击通知打开"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'androidOpen',
            {
              initialValue: _vm.jiguangConfig.androidConfig.openMethod
                ? [_vm.jiguangConfig.androidConfig.openMethod]
                : ['OPEN_APPLICATION'],
            } ]),expression:"[\n            'androidOpen',\n            {\n              initialValue: jiguangConfig.androidConfig.openMethod\n                ? [jiguangConfig.androidConfig.openMethod]\n                : ['OPEN_APPLICATION'],\n            },\n          ]"}],on:{"change":_vm.androidOpenChange}},[_c('a-checkbox',{attrs:{"value":"OPEN_APPLICATION"}},[_vm._v("打开应用")]),_c('a-checkbox',{attrs:{"value":"INTENT"}},[_vm._v("Intent(推荐)")]),_c('a-checkbox',{attrs:{"value":"DEEPLINK"}},[_vm._v("DeepLink")]),_c('a-checkbox',{attrs:{"value":"URL"}},[_vm._v("URL")])],1)],1),(
          ['INTENT', 'DEEPLINK', 'URL'].includes(
            _vm.jiguangConfig.androidConfig.openMethod
          )
        )?_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'androidExtras',
            {
              rules: [{ required: true, message: '请输入' }],
              initialValue:
                _vm.jiguangConfig.androidConfig.extras &&
                _vm.jiguangConfig.androidConfig.extras.intent
                  ? JSON.parse(_vm.jiguangConfig.androidConfig.extras.intent).url
                  : '',
            } ]),expression:"[\n            'androidExtras',\n            {\n              rules: [{ required: true, message: '请输入' }],\n              initialValue:\n                jiguangConfig.androidConfig.extras &&\n                jiguangConfig.androidConfig.extras.intent\n                  ? JSON.parse(jiguangConfig.androidConfig.extras.intent).url\n                  : '',\n            },\n          ]"}],attrs:{"placeholder":_vm.androidPlaceholder}})],1):_vm._e()],1):_vm._e(),(
        (_vm.templateData.channel == 'JIGUANG' || _vm.channel == 'JIGUANG') &&
        (_vm.jiguangConfig.plat.includes('IOS') || _vm.platform.includes('IOS'))
      )?_c('a-form-item',{attrs:{"label":"iOS"}},[_c('a-form-item',{attrs:{"label":"包名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'iosPackage',
            {
              rules: [{ required: true, message: '请填写ios包名' }],
              initialValue: _vm.jiguangConfig.iosConfig.jiguangPackageName,
            } ]),expression:"[\n            'iosPackage',\n            {\n              rules: [{ required: true, message: '请填写ios包名' }],\n              initialValue: jiguangConfig.iosConfig.jiguangPackageName,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"点击通知打开"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'iosOpen',
            {
              initialValue: _vm.jiguangConfig.iosConfig.openMethod
                ? [_vm.jiguangConfig.iosConfig.openMethod]
                : ['OPEN_APPLICATION'],
            } ]),expression:"[\n            'iosOpen',\n            {\n              initialValue: jiguangConfig.iosConfig.openMethod\n                ? [jiguangConfig.iosConfig.openMethod]\n                : ['OPEN_APPLICATION'],\n            },\n          ]"}],on:{"change":_vm.iosOpenChange}},[_c('a-checkbox',{attrs:{"value":"OPEN_APPLICATION"}},[_vm._v("打开应用")]),_c('a-checkbox',{attrs:{"value":"PAGE"}},[_vm._v("指定页面")]),_c('a-checkbox',{attrs:{"value":"URL"}},[_vm._v("URL")])],1)],1),(['URL'].includes(_vm.jiguangConfig.iosConfig.openMethod))?_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'iosExtras',
            {
              rules: [{ required: true, message: '请输入URL' }],
              initialValue:
                _vm.jiguangConfig.iosConfig.extras &&
                _vm.jiguangConfig.iosConfig.extras.intent
                  ? JSON.parse(_vm.jiguangConfig.iosConfig.extras.intent).url
                  : '',
            } ]),expression:"[\n            'iosExtras',\n            {\n              rules: [{ required: true, message: '请输入URL' }],\n              initialValue:\n                jiguangConfig.iosConfig.extras &&\n                jiguangConfig.iosConfig.extras.intent\n                  ? JSON.parse(jiguangConfig.iosConfig.extras.intent).url\n                  : '',\n            },\n          ]"}],attrs:{"placeholder":"请输入URL"}})],1):_vm._e(),(_vm.jiguangConfig.iosConfig.openMethod == 'PAGE')?_c('a-form-item',{staticClass:"row-input",attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            '_key',
            {
              initialValue: _vm.jiguangConfig.iosConfig.extras
                ? Object.keys(_vm.jiguangConfig.iosConfig.extras)[0]
                : '',
            } ]),expression:"[\n            '_key',\n            {\n              initialValue: jiguangConfig.iosConfig.extras\n                ? Object.keys(jiguangConfig.iosConfig.extras)[0]\n                : '',\n            },\n          ]"}],staticStyle:{"width":"120px"},attrs:{"placeholder":"键"}}),_vm._v(": "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'valueType',
            {
              initialValue: _vm.jiguangConfig.iosConfig.extras
                ? typeof Object.values(_vm.jiguangConfig.iosConfig.extras)[0]
                : 'string',
            } ]),expression:"[\n            'valueType',\n            {\n              initialValue: jiguangConfig.iosConfig.extras\n                ? typeof Object.values(jiguangConfig.iosConfig.extras)[0]\n                : 'string',\n            },\n          ]"}],staticStyle:{"width":"100px"},on:{"change":_vm.valueTypeChange}},[_c('a-select-option',{attrs:{"value":"string"}},[_vm._v("字符串")]),_c('a-select-option',{attrs:{"value":"boolean"}},[_vm._v("boolean")]),_c('a-select-option',{attrs:{"value":"number"}},[_vm._v("number")])],1),(_vm.valueType == 'boolean')?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            '_value',
            {
              initialValue: _vm.jiguangConfig.iosConfig.extras
                ? Object.values(_vm.jiguangConfig.iosConfig.extras)[0]
                : '',
            } ]),expression:"[\n            '_value',\n            {\n              initialValue: jiguangConfig.iosConfig.extras\n                ? Object.values(jiguangConfig.iosConfig.extras)[0]\n                : '',\n            },\n          ]"}],staticStyle:{"width":"400px"},attrs:{"placeholder":"值"}},[_c('a-select-option',{attrs:{"value":true}},[_vm._v("true")]),_c('a-select-option',{attrs:{"value":false}},[_vm._v("false")])],1):(_vm.valueType == 'number')?_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            '_value',
            {
              initialValue: _vm.jiguangConfig.iosConfig.extras
                ? Object.values(_vm.jiguangConfig.iosConfig.extras)[0]
                : 1,
            } ]),expression:"[\n            '_value',\n            {\n              initialValue: jiguangConfig.iosConfig.extras\n                ? Object.values(jiguangConfig.iosConfig.extras)[0]\n                : 1,\n            },\n          ]"}],staticStyle:{"width":"400px"},attrs:{"placeholder":"值"}}):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            '_value',
            {
              initialValue: _vm.jiguangConfig.iosConfig.extras
                ? Object.values(_vm.jiguangConfig.iosConfig.extras)[0]
                : '',
            } ]),expression:"[\n            '_value',\n            {\n              initialValue: jiguangConfig.iosConfig.extras\n                ? Object.values(jiguangConfig.iosConfig.extras)[0]\n                : '',\n            },\n          ]"}],staticStyle:{"width":"400px"},attrs:{"placeholder":"值"}})],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }