<template>
  <div class="notificationMenu">
    <div class="title">
      <span>{{ title }}</span
      ><span
        ><a-icon type="plus" style="color: #68b468" @click="add" /><a-icon
          style="color: #ff6868"
          type="minus"
          @click="del"
      /></span>
    </div>
    <!-- :selectedKeys="[title == '分组' ? defaultKey : defaultKey2]" -->
    <!-- 包含父子关系 -->
    <a-menu
      v-if="title == '分组' && menuData.length > 0"
      :selectedKeys="[defaultKey ? defaultKey : key]"
      :defaultOpenKeys="[defaultKey]"
      style="width: 200px; margin: 0 auto"
      mode="inline"
      @click="handleClick"
    >
      <template v-for="Item in multilevelMenu">
        <a-menu-item v-if="!Array.isArray(Item)" :key="Item.id"
          ><span class="hidden_text_one">{{ Item.name }}</span>
          <span class="oper_2">
            <slot
              name="operation"
              :record="Item"
              v-if="(defaultKey ? defaultKey : key) == Item.id"
            ></slot>
          </span>
        </a-menu-item>
        <a-sub-menu v-else :key="Item[0].id" :title="Item[0].name">
          <a-menu-item v-for="item in Item" :key="item.id">
            <span class="hidden_text_one">{{ item.name }}</span>
            <span class="oper_2">
              <slot
                name="operation"
                :record="item"
                v-if="(defaultKey ? defaultKey : key) == item.id"
              ></slot>
            </span>
          </a-menu-item>
        </a-sub-menu>
      </template>

      <!-- <a-menu-item v-for="item in menuData" :key="item.id"
        ><span class="hidden_text_one">{{ item.name }}</span
        ><slot
          name="operation"
          v-if="(defaultKey ? defaultKey : key) == item.id"
        ></slot
      ></a-menu-item> -->
    </a-menu>
    <!-- 非父子关系 -->
    <a-menu
      v-if="title !== '分组' && menuData.length > 0"
      :selectedKeys="[defaultKey ? defaultKey : key]"
      style="width: 200px; margin: 0 auto"
      mode="inline"
      @click="handleClick"
    >
      <a-menu-item v-for="item in menuData" :key="item.id"
        ><span class="hidden_text_one">{{ item.name }}</span>
        <span class="oper_0">
          <slot
            name="operation_0"
            :record="item"
            v-if="(defaultKey ? defaultKey : key) == item.id"
          ></slot>
        </span>
        <span class="oper_1">
          <slot
            name="operation_1"
            :record="item"
            v-if="(defaultKey ? defaultKey : key) == item.id"
          ></slot>
        </span>
        <span class="oper_2">
          <slot
            name="operation"
            :record="item"
            v-if="(defaultKey ? defaultKey : key) == item.id"
          ></slot>
        </span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
export default {
  name: 'NotificationMenu',
  data() {
    return {
      // key: this.menuData[0].id
      key: '',
      multilevelMenu: [],
    }
  },
  created() {
    this.init()
  },
  computed: {
    fatherMenu() {
      /* 处理菜单父子级关系的数据 */
      let fatherMenu = this.menuData.filter((item) => {
        return item.parentId == ''
      })

      return fatherMenu
    },
    childrenMenu() {
      let childrenMenu = this.menuData.filter((item) => {
        return item.parentId != ''
      })

      return childrenMenu
    },
  },
  watch: {
    menuData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.key = newValue[0].id
        this.init()
      },
      deep: true,
    },
    defaultKey: {
      handler(newVal, oldVal) {
        this.key = newVal
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    menuData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    defaultKey: {
      type: String,
      default: '',
    },
  },
  methods: {
    init() {
      this.multilevelMenu = this.menuData.filter((item) => {
        return item.parentId == ''
      })
      if (this.title == '分组') {
        this.changeMenuData(this.fatherMenu, this.childrenMenu)
        console.log(this.translateDataToTree(this.menuData), '树形结构数据')
      }
    },
    handleClick({ item, keyPath, key }) {
      console.log(item, 'item')
      this.key = key
      // console.log(keyPath)
      this.$emit('menuItemClick', key)
    },
    add() {
      this.$emit('add')
    },
    del() {
      this.$emit('del')
    },
    /* 菜单数据 */
    changeMenuData(fatherArr, childrenArr) {
      fatherArr = fatherArr.map((item) => {
        return (item = item.id)
      })

      childrenArr.forEach((item) => {
        fatherArr.forEach((item_f, index, arr) => {
          if (item_f == item.parentId) {
            // this.$set(this.multilevelMenu[index], 'children', [
            //   this.multilevelMenu[index],
            //   item
            // ])
            if (Array.isArray(this.multilevelMenu[index])) {
              this.multilevelMenu[index] = [...this.multilevelMenu[index], item]
            } else {
              this.multilevelMenu[index] = [this.multilevelMenu[index], item]
            }
          }
        })
      })

      // console.log(this.multilevelMenu, '多级菜单测试'(二级))
    },

    /* 将有父子关系的数组转化成树形结构数组 */
    translateDataToTree(data) {
      /* 没有父节点的数据 */
      let parents = data.filter(
        (item) => item.parentId == '' || item.parentId == null
      )
      /* 有父节点的数据 */
      let childrens = data.filter(
        (item) => item.parentId !== '' && item.parentId !== null
      )
      /* 定义转化的具体实现 */
      let translator = (parents, childrens) => {
        /* 遍历父节点数据 */
        parents.forEach((parent) => {
          /* 遍历子节点数据 */
          childrens.forEach((current, index) => {
            if (current.parentId === parent.id) {
              /* 对子节点进行深复制 */
              let temp = JSON.parse(JSON.stringify(childrens))
              /* 让当前子节点从temp中移去，temp作为新的子节点数据， */
              temp.splice(index, 1)
              /* 让当前子节点作为唯一的父节点，去递归查找对应的子节点 */
              translator([current], temp)
              /* 把找到的子节点放到父节点的childrens属性中 */
              typeof parent.childrens !== 'undefined'
                ? parent.childrens.push(current)
                : (parent.childrens = [current])
            }
          })
        })
      }
      /* 调用方法 */
      translator(parents, childrens)

      return parents
    },
  },
}
</script>

<style lang="less" scoped>
.notificationMenu {
  border: 1px #e8e8e8 solid;
  height: 100%;
  width: 220px;
  overflow: auto;
  background-color: #fff;
  border-radius: 3px;
  .title {
    height: 60px;
    // border-bottom: 1px #e8e8e8 solid;
    display: flex;
    align-items: center;
    position: relative;
    span {
      &:first-child {
        position: absolute;
        left: 30px;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        color: #151515;
        /* 伪类 */
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translateY(-50%);
          width: 4px;
          height: 20px;
          border-radius: 3px;
          background: #1890ff;
        }
      }
      &:last-child {
        position: absolute;
        right: 10px;
        i {
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }
  }
}

/deep/ .ant-menu-item,
.ant-menu-item-selected {
  .oper_0 {
    position: absolute;
    right: 80px;
  }
  .oper_1 {
    position: absolute;
    right: 45px;
  }
  .oper_2 {
    position: absolute;
    right: 10px;
  }
}

// 设置子菜单样式
/deep/ .ant-menu-submenu-active {
  // color: #151515 !important;
}
// 去掉右边框
.ant-menu-inline {
  border: none;
}
// 设置子菜单展开样式
/deep/ .ant-menu-submenu-title {
  color: #151515 !important;
  padding-left: 10px !important;
}

// a链接样式
.ant-menu-item a {
  color: #999999;
}
.ant-menu-item a:hover {
  color: #151515;
}
// 选中菜单状态
/deep/ .ant-menu-item-selected {
  position: relative;
  background-color: #ecf4fe !important;
  border-radius: 3px;
  a {
    color: #1890ff !important;
  }
  &::after {
    display: none;
  }
}

.hidden_text_one {
  position: absolute;
  left: 10px;
  width: 120px;
  overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap; /*文本不换行*/
}
</style>
