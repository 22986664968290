export default {
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
    }
  },
  created() {
    window.onresize = () => {
      return (() => {
        Window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = Window.fullHeight
      })()
    }
  },
  computed: {
    scrollHeight(value) {
      return function (value) {
        return this.windowHeight - value
      }
    },
  },
}
