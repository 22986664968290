var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.sceneVisible,"ok-text":"保存"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.isModify > 0 ? '编辑场景' : '添加场景')+" ")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称","label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: '请输入场景名称' }],
            initialValue: _vm.sceneData.name,
          } ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: '请输入场景名称' }],\n            initialValue: sceneData.name,\n          },\n        ]"}],attrs:{"placeholder":"请填写"}})],1),(_vm.isModify > 0)?_c('a-form-item',{attrs:{"label":"场景标识","label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'scenarioKey',
          {
            rules: [{ required: true, message: '请输入场景标识' }],
            initialValue: _vm.sceneData.scenarioKey,
          } ]),expression:"[\n          'scenarioKey',\n          {\n            rules: [{ required: true, message: '请输入场景标识' }],\n            initialValue: sceneData.scenarioKey,\n          },\n        ]"}],attrs:{"placeholder":"请填写"}})],1):_c('a-form-item',{attrs:{"label":"状态","label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'enable',
          { rules: [{ required: true }], initialValue: false } ]),expression:"[\n          'enable',\n          { rules: [{ required: true }], initialValue: false },\n        ]"}],attrs:{"checked-children":"开","un-checked-children":"关"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }