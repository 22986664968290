<template>
  <a-modal
    :visible="visible"
    title="添加变量"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item
        label="字段名称"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-input
          :maxLength="20"
          placeholder="请填写字段名称"
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '请填写字段名称' }] },
          ]"
        >
          <span slot="suffix">{{ inputLength1 }}/20</span>
        </a-input>
      </a-form-item>
      <a-form-item
        label="表达式"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-input
          :maxLength="20"
          placeholder="请填写表达式"
          v-decorator="[
            'expression',
            { rules: [{ required: true, message: '请填写表达式' }] },
          ]"
        >
          <span slot="suffix">{{ inputLength2 }}/20</span>
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'AddVariable',
  inject: ['reload'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    scenarioId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: (form, changeValue) => {
          let formItemName = Object.keys(changeValue)[0]
          if (formItemName === 'name') {
            this.inputLength1 = changeValue[formItemName].length
          } else if (formItemName == 'expression') {
            this.inputLength2 = changeValue[formItemName].length
          }
        },
      }),
      inputLength1: 0,
      inputLength2: 0,
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let data = {
            description: values.name,
            name: values.expression,
            scenarioId: this.scenarioId,
          }
          this.$api
            .addVariableOfScene(data)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success('添加成功')
                // this.reload()
                this.$emit('refresh')
              }
            })
            .catch((err) => {
              console.log(err, '错误')
            })
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="less" scoped></style>
