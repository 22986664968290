<template>
  <a-modal
    :visible="sceneVisible"
    @ok="handleOk"
    @cancel="handleCancel"
    ok-text="保存"
  >
    <div slot="title">
      {{ isModify > 0 ? '编辑场景' : '添加场景' }}
    </div>
    <a-form :form="form">
      <a-form-item
        label="名称"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请输入场景名称' }],
              initialValue: sceneData.name,
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        v-if="isModify > 0"
        label="场景标识"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'scenarioKey',
            {
              rules: [{ required: true, message: '请输入场景标识' }],
              initialValue: sceneData.scenarioKey,
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item
        v-else
        label="状态"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-switch
          checked-children="开"
          un-checked-children="关"
          v-decorator="[
            'enable',
            { rules: [{ required: true }], initialValue: false },
          ]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'Scene',
  inject: ['reload'],
  data() {
    return {
      form: this.$form.createForm(this),
      defaultGroupId: '91000000000000000000',
      channelList: ['TENCENT_SMS', 'HUAWEI_SMS', 'WX_MP', 'EMAIL'],
    }
  },
  props: {
    sceneVisible: {
      type: Boolean,
      default: false,
    },
    sceneData: {
      type: Object,
      default: () => {},
    },
    groupId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    isModify() {
      return Object.keys(this.sceneData).length
    },
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values, '表单数据')
          if (this.isModify) {
            /* 修改场景 */
            let data = {
              ...values,
              groupId: this.groupId ? this.groupId : this.defaultGroupId,
            }
            this.updateSceneInfo(this.sceneData.scenarioId, data).then(() => {
              this.$message.success('修改成功')
              this.$store.commit('notification/savePosition2', {
                scenarioKey: values.scenarioKey,
              })
              this.$emit('refresh')
            })
          } else {
            /* 添加场景 */
            let data = {
              ...values,
              scenarioKey: `${this.groupId}_${values.name}`,
              groupId: this.groupId ? this.groupId : this.defaultGroupId,
            }
            this.$api.addNotificationScene(data).then((res) => {
              if (res.code === 0) {
                /* 添加场景成功后，为场景添加默认的是个渠道（模板） */
                // Promise.all(this.addTemplates('新增加的场景id？')).then(
                //   (res) => {
                this.$message.success('添加成功')
                this.$emit('refresh')
                //   }
                // )
              }
            })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:sceneVisible', false)
    },
    /* 跟新场景信息 */
    updateSceneInfo(scenarioId, data) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateScene(scenarioId, data)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success('保存成功')
              resolve()
            }
          })
          .catch((err) => {
            console.log(err, '更新失败')
            reject()
          })
      })
    },
    /* 添加默认模板 */
    addDefaultTemplate(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .addTemplateChannel(data)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject()
          })
      })
    },
    /* 添加四个默认模板 */
    addTemplates(scenarioId) {
      return this.channelList.map((item) => {
        let data = {
          channel: item,
          content: '',
          scenarioId: scenarioId,
          status: 'DISABLED',
          thirdPartContent: '',
          thirdPartSignature: '',
          thirdPartTemplateId: '',
          variableIds: [],
        }
        return (item = this.addDefaultTemplate(data))
      })
    },
    /* 保留局部刷新前的位置状态方法 */
    savePosition() {
      let position = {
        groupMenuKey: this.groupId,
        sceneMenuKey: '',
        tabKey: 1,
        scenarioKey: '',
      }

      this.$store.commit('notification/savePosition', position)
    },
  },
  beforeDestroy() {
    this.savePosition()
  },
}
</script>

<style lang="less" scoped></style>
