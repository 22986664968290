<template>
  <a-modal
    :visible="delSceneVisible"
    title="删除场景"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item
        label="场景名称"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-select
          @change="selectChange"
          v-decorator="[
            'sceneId',
            {
              rules: [{ required: true, message: '选择场景' }],
              initialValue: sceneId,
            },
          ]"
        >
          <a-select-option
            v-for="item in sceneList"
            :key="item.scenarioId"
            :value="item.scenarioId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'DelScene',
  inject: ['reload'],
  props: {
    delSceneVisible: {
      type: Boolean,
      default: false,
    },
    sceneList: {
      type: Array,
      default: () => [],
    },
    sceneId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let _this = this
          this.$confirm({
            title: '你确定要删除这条场景？',
            onOk() {
              _this.$api
                .deleteNotificationScene(values.sceneId)
                .then((res) => {
                  if (res.code === 0) {
                    _this.$message.success('删除成功')
                    _this.$emit('refresh')
                  }
                })
                .catch((err) => {
                  _this.$message.error('删除失败')
                  _this.$emit('refresh')
                })
            },
            onCancel() {},
          })
        }
      })
    },
    handleCancel() {
      this.$emit('update:delSceneVisible', false)
    },
    selectChange() {},
  },
}
</script>

<style lang="less" scoped></style>
